//意见反馈
const findFeedbackPageUrl = `/gateway/hc-serve/manage/feedback/findFeedbackPage`;
//意见反馈
const saveOrUpdateFeedbackUrl = `/gateway/hc-serve/manage/feedback/saveOrUpdateFeedback`;
//意见详情
const getFeedbackByldUrl = `/gateway/hc-serve/manage/feedback/getFeedbackById`;
//删除

const removeFeedbackByldUrl = `/gateway/hc-serve/manage/feedback/removeFeedbackById`;
export {
  findFeedbackPageUrl,
  removeFeedbackByldUrl,
  getFeedbackByldUrl,
  saveOrUpdateFeedbackUrl,
};
