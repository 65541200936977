<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot> </template>
      <template #searchSlot>
        <v-input label="关键词" clearable v-model="searchParam.content" />
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="toCancel(scope.row)" />
        <v-button text="回复" type="text" @click="viewReply(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      title="回复"
      sureTxt="提交"
      v-model="isShowDialog"
      class="dialog"
      @confirm="confirm"
    >
      <div class="item">
        <div class="item-l">用户意见:</div>
        <div class="item-r">{{ oData.content }}</div>
      </div>
      <div class="item">
        <div class="item-l">回复:</div>
        <div class="item-r">
          <v-input
            clearable
            type="textarea"
            placeholder="请输入意见"
            :width="310"
            :maxlength="10000"
            v-model="form.managerContent"
          ></v-input>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  findFeedbackPageUrl,
  getFeedbackByldUrl,
  saveOrUpdateFeedbackUrl,
  removeFeedbackByldUrl,
} from "./api.js";
import { createAlinkVNode } from "@/utils/utils.js";

export default {
  name: "realNameAuditList",
  data() {
    return {
      timer: null,
      isShowDialog: false,
      searchParam: {
        content: "",
        tenantId: this.$store.state.app.userInfo.tenantId,
      },
      oData: {},
      form: {
        managerContent: "",
        tenantId: this.$store.state.app.userInfo.tenantId,
        insertUser: this.$store.state.app.userInfo.userId,
      },
      tableUrl: findFeedbackPageUrl,
      headers: [
        {
          prop: "content",
          label: "内容",
        },
        {
          prop: "managerContent",
          label: "回复",
        },
        {
          prop: "insertUserName",
          label: "用户",
        },
        {
          prop: "insertTime",
          label: "日期",
        },
      ],
    };
  },
  computed: {
    parentName() {
      return this.$route.query.parentName;
    },
  },
  created() {},
  beforeDestroy() {
    this.$clearBreadList();
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    async confirm() {
      if (!this.form.managerContent) {
        this.$message.error("请填写回复");
        return;
      }
      const params = {
        id: this.oData.id,
        managerContent: this.form.managerContent,
      };
      let res = await this.$axios.post(saveOrUpdateFeedbackUrl, params);
      if (res.code == 200) {
        this.isShowDialog = false;
        this.$refs.list.search();
      }
    },
    async getDetail(row) {
      const params = {
        id: row.id,
      };
      let res = await this.$axios.get(getFeedbackByldUrl, { params });
      if (res.code == 200) {
        this.oData = res.data;
        this.form.managerContent = this.oData.managerContent;
        return res;
      }
    },
    toCancel(data) {
      this.$confirm("你确定要删除吗", "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(removeFeedbackByldUrl, null, {
              params: {
                id: data.id,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("删除成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },

    toUserLabelList(row) {
      this.$router.push({
        name: "userNumberList",
        query: {
          labelId: row.id,
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    toAdd() {
      this.$router.push({
        name: "userLabelForm",
        query: {
          labelLevel: this.extraParams.labelLevel,
          parentId: this.extraParams.parentId,
          parentName: this.parentName,
        },
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "userLabelForm",
        query: {
          id: row.id,
          labelLevel: row.labelLevel,
        },
      });
    },
    async viewReply(row) {
      let res = await this.getDetail(row);
      if (res.code == 200) {
        this.isShowDialog = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
  .item {
    display: flex;
    margin: 20px 0;
    .item-l {
      width: 68px;
    }
    .item-r {
      flex: 1;
    }
  }
  /deep/ .el-textarea {
    height: 200px !important;
    textarea {
      height: 100%;
    }
  }
}
</style>
